<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Empresa" :rules="rules" :headers="headers" :inputs="inputs" />
</template>

<script>
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import { Company } from '../../../models/general/Company';
import Catalog from "../../../components/templates/BasicCatalog.vue";

export default {
  props: {
    voucher_type: null
  },
  data() {
    return {
      test: null,
      entity: new Company(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", { cols: 8 }),
        new CatalogInput("Clave", "key_name", "text", { cols: 4 }),
        new CatalogInput("Direccion", "address", "text", { cols: 6 }),
        new CatalogInput("Colonia", "suburb", "text", { cols: 6 }),
        new CatalogInput("Ciudad", "city", "text", { cols: 6 }),
        new CatalogInput("Estado", "state", "text", { cols: 6 }),
        new CatalogInput("RFC", "tax_number", "text", { cols: 3 }),
        new CatalogInput("Codigo Postal", "zip_code", "text", { cols: 3 }),
        new CatalogInput("Telefono", "phone", "text", { cols: 3 }),
        new CatalogInput("Activo", "id_active", "switch", { cols: 3 }),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "address" }),
        new Rule({ name: "suburb" }),
        new Rule({ name: "city" }),
        new Rule({ name: "state" }),
        new Rule({ name: "tax_number" }),
        new Rule({ name: "zip_code" }),
        new Rule({ name: "phone" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Direccion", "address"),
        new HeaderGrid("Colonia", "suburb"),
        new HeaderGrid("Ciudad", "city"),
        new HeaderGrid("Estado", "state"),
        new HeaderGrid("RFC", "tax_number"),
      ],
      validate: new ValidateForm()    
      }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new Company(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new Company(this.session); 
  }
}
</script>

<style>

</style>